@font-face {
  font-family: "Optum Sans";
  font-weight: 500;
  font-display: swap;
  src: local("myfont"),
    url("../OptumSans-Regular.4caac01c.woff2") format("woff");
}

@font-face {
  font-family: "Optum Sans";
  font-weight: 900;
  font-display: swap;
  src: local("myfont"), url("../OptumSans-Bold.41bf3143.woff2") format("woff");
}

body {
  color: #002577;
  margin: 0 auto;
  padding: 0;
  font-family: Optum Sans, Arial, sans-serif;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: unset;
}
