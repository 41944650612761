@font-face {
  font-family: Optum Sans;
  font-weight: 500;
  font-display: swap;
  src: local(myfont), url("OptumSans-Regular.4caac01c.ee62e15c.woff2") format("woff");
}

@font-face {
  font-family: Optum Sans;
  font-weight: 900;
  font-display: swap;
  src: local(myfont), url("OptumSans-Bold.41bf3143.6d0a5de3.woff2") format("woff");
}

body {
  color: #002577;
  margin: 0 auto;
  padding: 0;
  font-family: Optum Sans, Arial, sans-serif;
  font-size: 16px;
}

a {
  color: unset;
  text-decoration: none;
}

.ety_Sa_headerContent {
  box-sizing: border-box;
  background-color: #faf8f2;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 72px;
  padding: 20px 30px;
  display: flex;
}

@media (width >= 768px) {
  .ety_Sa_headerContent {
    padding: 30px 50px;
  }
}

.ety_Sa_optumContainer {
  border-right: 1px solid #d3d3d3;
  justify-content: center;
  padding-right: 25px;
  display: flex;
}

.ety_Sa_logoContainer {
  padding-left: 25px;
}

.ety_Sa_optumContainer img {
  width: 100px;
  height: 30px;
}

@media (width >= 768px) {
  .ety_Sa_optumContainer img {
    width: 137px;
    height: 40px;
  }
}

.ety_Sa_logoContainer img {
  width: 75px;
  height: 30px;
}

@media (width >= 768px) {
  .ety_Sa_logoContainer img {
    width: 93px;
    height: 40px;
  }
}

.jitYDq_bannerContent {
  padding: 0;
}

.jitYDq_bannerContent .jitYDq_bannerContainer {
  width: 100%;
  margin: 0;
}

@media (width >= 768px) {
  .jitYDq_bannerContent .jitYDq_bannerContainer {
    flex-direction: row-reverse;
  }
}

.jitYDq_bannerImage {
  height: 250px;
}

@media (width >= 768px) {
  .jitYDq_bannerImage {
    height: unset;
  }
}

.jitYDq_bannerText {
  padding-left: 20px;
  padding-right: 20px;
}

@media (width >= 768px) {
  .jitYDq_bannerText {
    padding: 30px 50px;
  }
}

.jitYDq_bannerText h1 {
  margin-bottom: 12px;
  font-size: 44px;
  font-weight: 900;
  line-height: 52px;
}

.jitYDq_bannerText p {
  margin-top: 0;
  font-size: 18px;
}

@media (width >= 992px) {
  .jitYDq_bannerText p {
    font-size: 20px;
  }

  .jitYDq_bannerText h2 {
    font-size: 32px;
  }
}

.iObf1G_servicesContent {
  text-align: center;
  background-color: #faf8f2;
  padding: 40px 20px;
}

.iObf1G_servicesContent p {
  font-size: 18px;
}

.iObf1G_servicesContent a {
  color: #186dcf;
}

.iObf1G_title {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 768px) {
  .iObf1G_title {
    font-size: 2em;
  }
}

.iObf1G_servicesItemWrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.iObf1G_servicesItemContainer {
  max-width: 1100px;
  margin: 50px auto 0;
}

.iObf1G_servicesItem {
  background-color: #fff;
  border-radius: 50%;
  align-items: center;
  width: 300px;
  height: 300px;
  margin: 15px;
  display: flex;
}

.iObf1G_servicesItem h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.iObf1G_servicesItem p {
  margin-top: 12px;
}

.iObf1G_item {
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
}

.iObf1G_star {
  margin-top: 25px;
}

.iObf1G_virtualCare {
  margin-top: 16px;
}

.__oPnG_button {
  cursor: pointer;
  color: #faf8f2;
  background-color: #002577;
  border: 0;
  border-radius: 20px;
  padding: 10px 35px;
  font-family: Optum Sans, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

@media (width >= 992px) {
  .__oPnG_button {
    font-size: 16px;
  }
}

.InLx6a_supportContent {
  background-color: #d9f6f9;
  padding: 60px 20px;
}

.InLx6a_supportTitle {
  text-align: center;
  padding-bottom: 15px;
}

.InLx6a_supportTitleText {
  border-bottom: 1px solid;
  padding-bottom: 15px;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 60px;
}

.InLx6a_supportItem {
  padding-top: 50px;
}

.InLx6a_supportImage {
  height: 315px;
}

.InLx6a_supportItemWrapper h1 {
  margin-bottom: 12px;
  font-size: 28px;
  font-weight: 900;
}

@media (width >= 992px) {
  .InLx6a_supportItemWrapper h1 {
    margin-right: 30px;
    font-size: 44px;
    line-height: 52px;
  }
}

.InLx6a_supportItemWrapper p {
  margin-top: 0;
}

@media (width >= 992px) {
  .InLx6a_supportItemWrapper p {
    font-size: 18px;
  }
}

.InLx6a_supportButton {
  margin-top: 10px;
  margin-right: 10px;
}

.InLx6a_supportWrapper {
  max-width: 1100px;
  margin: auto;
}

.Zrzj3W_directorQuoteContent {
  background-color: #fff;
  padding: 50px 20px;
}

.Zrzj3W_directorQuoteWrapper {
  max-width: 1025px;
  margin: auto;
}

.Zrzj3W_directorImage, .Zrzj3W_directorInfo, .Zrzj3W_quoteImage {
  place-content: center;
  display: flex;
}

.Zrzj3W_directorInfo {
  text-align: center;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Zrzj3W_directorName {
  color: #002577;
  padding-bottom: 5px;
  font-weight: 700;
}

.Zrzj3W_directorPos {
  color: #5a5a5a;
  font-size: 14px;
}

.Zrzj3W_directorQuote {
  place-content: center;
  margin-top: 20px;
  display: flex;
}

@media (width >= 768px) {
  .Zrzj3W_directorQuote {
    margin-top: 0;
  }
}

.Zrzj3W_quoteImage {
  margin-right: 20px;
}

.Zrzj3W_quoteContent {
  text-align: left;
}

@media (width >= 768px) {
  .Zrzj3W_quoteContent {
    font-size: 18px;
  }
}

.Zrzj3W_quoteLink {
  color: #1c70cf;
  font-weight: 700;
}

.thDDtG_supportTeensContent {
  background-color: #f9f8f3;
  padding: 60px 20px;
}

.thDDtG_supportTeensContent .thDDtG_selfCareWrapper {
  margin: auto;
}

.thDDtG_supportTitle {
  text-align: center;
  padding-bottom: 15px;
}

.thDDtG_supportTitleText {
  border-bottom: 1px solid;
  padding-bottom: 15px;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 60px;
}

.thDDtG_supportWrapper {
  max-width: 845px;
  margin-top: 50px;
}

@media (width >= 768px) {
  .thDDtG_supportWrapper {
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
  }
}

.thDDtG_selfCareImageWrapper {
  margin-bottom: 30px;
}

.thDDtG_useCode {
  font-size: 22px;
}

.thDDtG_selfCareImage {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

.thDDtG_selfCareText {
  text-align: center;
}

@media (width >= 768px) {
  .thDDtG_selfCareText {
    text-align: left;
    padding-left: 30px;
  }

  .thDDtG_selfCareText h1 {
    margin-top: 0;
  }
}

.thDDtG_storeContainer {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (width >= 768px) {
  .thDDtG_storeContainer {
    justify-content: flex-start;
  }
}

.thDDtG_storeContainer img {
  padding: 0 5px;
}

@media (width >= 768px) {
  .thDDtG_storeContainer img {
    padding-left: 0;
  }
}

.thDDtG_boxWrapper {
  max-width: 900px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 768px) {
  .thDDtG_boxWrapper {
    margin-top: 20px;
  }
}

.thDDtG_boxItem {
  text-align: center;
  background-color: #fff;
  max-width: 250px;
  margin: 8px auto;
  padding: 20px 40px;
  box-shadow: 0 1px 4px #00000029;
}

.thDDtG_boxItem h3 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 22px;
}

.thDDtG_link {
  color: #1c70cf;
  font-size: 18px;
  font-weight: 700;
}

.aet_Ra_helpContent {
  background-color: #d9f6f9;
  padding: 40px;
}

@media (width >= 992px) {
  .aet_Ra_helpContent {
    padding: 20px 40px;
  }
}

.aet_Ra_helpWrapper {
  max-width: 1100px;
  margin: auto;
}

@media (width >= 992px) {
  .aet_Ra_helpWrapper {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.aet_Ra_helpTexts h1 {
  margin-bottom: 0;
  font-size: 36px;
}

.aet_Ra_helpTexts p {
  margin-top: 10px;
  font-size: 18px;
}

._3p0KfG_footerContent {
  background-color: #fff;
  padding: 20px 40px;
}

._3p0KfG_footerWrapper {
  color: #4b4d4f;
  max-width: 1100px;
  margin: auto;
  font-size: 14px;
}

._3p0KfG_footerWrapper p {
  margin: 20px 0;
}

.QoO7DW_appContainer {
  cursor: default;
  background-color: #fff;
  justify-content: center;
  display: flex;
}

.QoO7DW_app {
  width: 100%;
  max-width: 1440px;
  position: relative;
  box-shadow: 1px 0 #d0d0ce, -1px 0 #d0d0ce;
}

/*# sourceMappingURL=index.6ed9a5f8.css.map */
